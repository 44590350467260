
import React, { useEffect } from 'react'
import { Box } from './components/Styled/Elements';
import { useLocation } from 'react-router-dom';
import Percentage from './components/percentage-calculate/Percentage';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);

  return (
    <>
      <Box className='main_Container'>
        <Percentage />

      </Box>
    </>
  );
}

export default App;
