import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, SpanStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const [fromUnit, setFromUnit] = useState('Cubic Meter');
    const [toUnit, setToUnit] = useState('Cubic Meter');
    const [outputValue, setOutputValue] = useState('');
    const [resultValue, setResultValue] = useState(null);

    const units = [
        'Celsius',
        'Kelvin',
        'Fahrenheit',
        'Cubic_Millimeter',
        'Liter',
        'Mililiter',
        'US_Gallon',
        'US_Quart',
        'US_Pint',
        'US_Cup',
        'US_Fluid_Ounce',
        'US_Table_Spoon',
        'US_Tea_Spoon',
        'Imperial_Gallon',
        'Imperial_Quart',
        'Imperial_Pint',
        'Imperial_Fluid_Ounce',
        'Imperial_Table_Spoon',
        'Imperial_Tea_Spoon',
        'Cubic_Mile',
        'Cubic_Yard',
        'Cubic_Foot',
        'Cubic_Inch'
        // 'Light_Year',
    ];



    const convertFromCubicMeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value;
            case 'Cubic Kilometer':
                return value * 1e-9;
            case 'Cubic Centimeter':
                return value * 1000000;
            case 'Cubic Millimeter':
                return value * 1000000000;
            case 'Liter':
                return value * 1000;
            case 'Mililiter':
                return value * 1000000;
            case 'US Gallon':
                return value * 264.172;
            case 'US Quart':
                return value * 1056.6887074;
            case 'US Pint':
                return value * 2113.38;
            case 'US Cup':
                return value * 4226.7548297;
            case 'US Fluid Ounce':
                return value * 33814.02;
            case 'US Table Spoon':
                return value * 67628.05;
            case 'US Tea Spoon':
                return value * 202884.14;
            case 'Imperial Gallon':
                return value * 219.969;
            case 'Imperial Quart':
                return value * 879.8769932;
            case 'Imperial Pint':
                return value * 1759.75;
            case 'Imperial Fluid Ounce':
                return value * 35195.08;
            case 'Imperial Table Spoon':
                return value * 56312.127565;
            case 'Imperial Tea Spoon':
                return value * 168936.38269;
            case 'Cubic Mile':
                return value * 2.3991e-10;
            case 'Cubic Yard':
                return value * 1.30795;
            case 'Cubic Foot':
                return value * 35.3147;
            case 'Cubic Inch':
                return value * 61023.744095;

            // case 'Light_Year':
            //     return value / 9.461e15;
            default:
                return ' ';
        }
    };
    const convertFromCubicKilometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 1e9;
            case 'Cubic Kilometer':
                return value;
            case 'Cubic Centimeter':
                return value * 1e15;
            case 'Cubic Millimeter':
                return value * 1e18;
            case 'Liter':
                return value * 1e12;
            case 'Mililiter':
                return value * 1e15;
            case 'US Gallon':
                return value * 264172176858;
            case 'US Quart':
                return value * 1056688707432;
            case 'US Pint':
                return value * 2113377414864;
            case 'US Cup':
                return value * 4226754829728;
            case 'US Fluid Ounce':
                return value * 33814038637823;
            case 'US Table Spoon':
                return value * 67628077275645;
            case 'US Tea Spoon':
                return value * 202884231826936;
            case 'Imperial Gallon':
                return value * 219969248299;
            case 'Imperial Quart':
                return value * 879876993196;
            case 'Imperial Pint':
                return value * 1759753986393;
            case 'Imperial Fluid Ounce':
                return value * 35195079727854;
            case 'Imperial Table Spoon':
                return value * 56312127564566;
            case 'Imperial Tea Spoon':
                return value * 168936382693699;
            case 'Cubic Mile':
                return value * 0.2399128636;
            case 'Cubic Yard':
                return value * 1307950619.3;
            case 'Cubic Foot':
                return value * 35314666721;
            case 'Cubic Inch':
                return value * 61023744094732;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromCubicCentimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.000001;
            case 'Cubic Kilometer':
                return value * 9.999999999E-16;
            case 'Cubic Centimeter':
                return value;
            case 'Cubic Millimeter':
                return value * 1000;
            case 'Liter':
                return value * 0.001;
            case 'Mililiter':
                return value;
            case 'US Gallon':
                return value * 0.000264172;
            case 'US Quart':
                return value * 0.0010566887;
            case 'US Pint':
                return value * 0.0021133774;
            case 'US Cup':
                return value * 0.0042267548;
            case 'US Fluid Ounce':
                return value * 0.0338140386;
            case 'US Table Spoon':
                return value * 0.0676280773;
            case 'US Tea Spoon':
                return value * 0.2028842318;
            case 'Imperial Gallon':
                return value * 0.0002199692;
            case 'Imperial Quart':
                return value * 0.000879877;
            case 'Imperial Pint':
                return value * 0.001759754;
            case 'Imperial Fluid Ounce':
                return value * 0.0351950797;
            case 'Imperial Table Spoon':
                return value * 0.0563121276;
            case 'Imperial Tea Spoon':
                return value * 0.1689363827;
            case 'Cubic Mile':
                return value * 2.399128636E-16;
            case 'Cubic Yard':
                return value * 0.000001308;
            case 'Cubic Foot':
                return value * 0.0000353147;
            case 'Cubic Inch':
                return value * 0.0610237441;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromCubicMillimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 1e-9;
            case 'Cubic Kilometer':
                return value * 1e-18;
            case 'Cubic Centimeter':
                return value * 0.001;
            case 'Cubic Millimeter':
                return value;
            case 'Liter':
                return value * 0.000001;
            case 'Mililiter':
                return value * 0.001;
            case 'US Gallon':
                return value * 2.641721768E-7;
            case 'US Quart':
                return value * 8.798769931;
            case 'US Pint':
                return value * 0.0000021134;
            case 'US Cup':
                return value * 0.0000042268;
            case 'US Fluid Ounce':
                return value * 0.000033814;
            case 'US Table Spoon':
                return value * 0.0000676281;
            case 'US Tea Spoon':
                return value * 0.0002028842;
            case 'Imperial Gallon':
                return value * 2.199692482E-7;
            case 'Imperial Quart':
                return value * 8.798769931E-7;
            case 'Imperial Pint':
                return value * 0.0000017598;
            case 'Imperial Fluid Ounce':
                return value * 0.0000351951;
            case 'Imperial Table Spoon':
                return value * 0.0000563121;
            case 'Imperial Tea Spoon':
                return value * 0.0001689364;
            case 'Cubic Mile':
                return value * 2.399128636E-19;
            case 'Cubic Yard':
                return value * 1.307950619E-9;
            case 'Cubic Foot':
                return value * 3.531466672E-8;
            case 'Cubic Inch':
                return value * 0.0000610237;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromLiter = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.001;
            case 'Cubic Kilometer':
                return value * 1e-12;
            case 'Cubic Centimeter':
                return value * 1000;
            case 'Cubic Millimeter':
                return value * 1e6;
            case 'Liter':
                return value;
            case 'Mililiter':
                return value * 1000;
            case 'US Gallon':
                return value * 0.264172;
            case 'US Quart':
                return value * 1.0566887074;
            case 'US Pint':
                return value * 2.11338;
            case 'US Cup':
                return value * 4.2267548297;
            case 'US Fluid Ounce':
                return value * 33.814;
            case 'US Table Spoon':
                return value * 67.628;
            case 'US Tea Spoon':
                return value * 202.884;
            case 'Imperial Gallon':
                return value * 0.219969;
            case 'Imperial Quart':
                return value * 0.8798769932;
            case 'Imperial Pint':
                return value * 1.75975;
            case 'Imperial Fluid Ounce':
                return value * 35.1951;
            case 'Imperial Table Spoon':
                return value * 56.312127565;
            case 'Imperial Tea Spoon':
                return value * 168.93638269;
            case 'Cubic Mile':
                return value * 2.399128636E-13;
            case 'Cubic Yard':
                return value * 0.0013079506;
            case 'Cubic Foot':
                return value * 0.0353146667;
            case 'Cubic Inch':
                return value * 61.023744095;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromMililiter = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.000001;
            case 'Cubic Kilometer':
                return value * 9.999999999E-16;
            case 'Cubic Centimeter':
                return value;
            case 'Cubic Millimeter':
                return value * 1000;
            case 'Liter':
                return value * 0.001;
            case 'Mililiter':
                return value;
            case 'US Gallon':
                return value * 0.0002641722;
            case 'US Quart':
                return value * 0.0010566887;
            case 'US Pint':
                return value * 0.0021133774;
            case 'US Cup':
                return value * 0.0042267548;
            case 'US Fluid Ounce':
                return value * 0.0338140386;
            case 'US Table Spoon':
                return value * 0.0676280773;
            case 'US Tea Spoon':
                return value * 0.2028842318;
            case 'Imperial Gallon':
                return value * 0.0002199692;
            case 'Imperial Quart':
                return value * 0.000879877;
            case 'Imperial Pint':
                return value * 0.001759754;
            case 'Imperial Fluid Ounce':
                return value * 0.0351951;
            case 'Imperial Table Spoon':
                return value * 0.0563121276;
            case 'Imperial Tea Spoon':
                return value * 0.1689363827;
            case 'Cubic Mile':
                return value * 2.399128636E-16;
            case 'Cubic Yard':
                return value * 0.000001308;
            case 'Cubic Foot':
                return value * 0.0000353147;
            case 'Cubic Inch':
                return value * 0.0610237441;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsGallon = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.00378541;
            case 'Cubic Kilometer':
                return value * 3.78541E-12;
            case 'Cubic Centimeter':
                return value * 3785.41;
            case 'Cubic Millimeter':
                return value * 3785410;
            case 'Liter':
                return value * 3.78541;
            case 'Mililiter':
                return value * 3785.41;
            case 'US Gallon':
                return value;
            case 'US Quart':
                return value * 4;
            case 'US Pint':
                return value * 8;
            case 'US Cup':
                return value * 16;
            case 'US Fluid Ounce':
                return value * 128;
            case 'US Table Spoon':
                return value * 256;
            case 'US Tea Spoon':
                return value * 768;
            case 'Imperial Gallon':
                return value * 0.832674;
            case 'Imperial Quart':
                return value * 3.3306951688;
            case 'Imperial Pint':
                return value * 6.66139;
            case 'Imperial Fluid Ounce':
                return value * 133.227;
            case 'Imperial Table Spoon':
                return value * 213.1644908;
            case 'Imperial Tea Spoon':
                return value * 639.49347241;
            case 'Cubic Mile':
                return value * 9.081685531E-13;
            case 'Cubic Yard':
                return value * 0.0049511294;
            case 'Cubic Foot':
                return value * 0.1336804926;
            case 'Cubic Inch':
                return value * 231;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsQuart = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0009463525;
            case 'Cubic Kilometer':
                return value * 9.463525E-13;
            case 'Cubic Centimeter':
                return value * 946.3525;
            case 'Cubic Millimeter':
                return value * 946352.5;
            case 'Liter':
                return value * 0.9463525;
            case 'Mililiter':
                return value * 946.3525;
            case 'US Gallon':
                return value * 0.25;
            case 'US Quart':
                return value;
            case 'US Pint':
                return value * 2;
            case 'US Cup':
                return value * 4;
            case 'US Fluid Ounce': 
                return value * 32;
            case 'US Table Spoon':
                return value * 64;
            case 'US Tea Spoon':
                return value * 192;
            case 'Imperial Gallon':
                return value * 0.2081684481;
            case 'Imperial Quart':
                return value * 0.8326737922;
            case 'Imperial Pint':
                return value * 1.6653475844;
            case 'Imperial Fluid Ounce':
                return value * 33.306951688;
            case 'Imperial Table Spoon':
                return value * 53.291122701;
            case 'Imperial Tea Spoon':
                return value * 159.8733681;
            case 'Cubic Mile':
                return value * 2.270421382E-13;
            case 'Cubic Yard':
                return value * 0.0012377823;
            case 'Cubic Foot':
                return value * 0.0334201231;
            case 'Cubic Inch':
                return value * 57.749972783;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsPint = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0004731763;
            case 'Cubic Kilometer':
                return value * 4.7317625E-13;
            case 'Cubic Centimeter':
                return value * 473.17625;
            case 'Cubic Millimeter':
                return value * 473176.25;
            case 'Liter':
                return value * 0.47317625;
            case 'Mililiter':
                return value * 473.17625;
            case 'US Gallon':
                return value * 0.125;
            case 'US Quart':
                return value * 0.5;
            case 'US Pint':
                return value;
            case 'US Cup':
                return value * 2;
            case 'US Fluid Ounce':
                return value * 16;
            case 'US Table Spoon':
                return value * 32;
            case 'US Tea Spoon':
                return value * 96;
            case 'Imperial Gallon':
                return value * 0.104084224;
            case 'Imperial Quart':
                return value * 0.4163368961;
            case 'Imperial Pint':
                return value * 0.8326737922;
            case 'Imperial Fluid Ounce':
                return value * 16.653475844;
            case 'Imperial Table Spoon':
                return value * 26.645561351;
            case 'Imperial Tea Spoon':
                return value * 79.936684052;
            case 'Cubic Mile':
                return value * 1.135210691E-13;
            case 'Cubic Yard':
                return value * 0.0006188912;
            case 'Cubic Foot':
                return value * 0.0167100616;
            case 'Cubic Inch':
                return value * 28.874986392;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsCup = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0002365881;
            case 'Cubic Kilometer':
                return value * 2.36588125E-13;
            case 'Cubic Centimeter':
                return value * 236.588125;
            case 'Cubic Millimeter':
                return value * 236588.125;
            case 'Liter':
                return value * 0.236588125;
            case 'Mililiter':
                return value * 236.588125;
            case 'US Gallon':
                return value * 0.0625;
            case 'US Quart':
                return value * 0.25;
            case 'US Pint':
                return value * 0.5;
            case 'US Cup':
                return value;
            case 'US Fluid Ounce':
                return value * 8;
            case 'US Table Spoon':
                return value * 16;
            case 'US Tea Spoon':
                return value * 48;
            case 'Imperial Gallon':
                return value * 0.052042112;
            case 'Imperial Quart':
                return value * 0.2081684481;
            case 'Imperial Pint':
                return value * 0.4163368961;
            case 'Imperial Fluid Ounce':
                return value * 8.326737922;
            case 'Imperial Table Spoon':
                return value * 13.322780675;
            case 'Imperial Tea Spoon':
                return value * 39.968342026;
            case 'Cubic Mile':
                return value * 5.676053457E-14;
            case 'Cubic Yard':
                return value * 0.0003094456;
            case 'Cubic Foot':
                return value * 0.0083550308;
            case 'Cubic Inch':
                return value * 14.437493196;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsFluidOunce = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0000295735;
            case 'Cubic Kilometer':
                return value * 2.957351562E-14;
            case 'Cubic Centimeter':
                return value * 29.573515625;
            case 'Cubic Millimeter':
                return value * 29573.515625;
            case 'Liter':
                return value * 0.0295735156;
            case 'Mililiter':
                return value * 29.573515625;
            case 'US Gallon':
                return value * 0.0078125;
            case 'US Quart':
                return value * 0.03125;
            case 'US Pint':
                return value * 0.0625;
            case 'US Cup':
                return value * 0.125;
            case 'US Fluid Ounce':
                return value;
            case 'US Table Spoon':
                return value * 2;
            case 'US Tea Spoon':
                return value * 6;
            case 'Imperial Gallon':
                return value * 0.006505264;
            case 'Imperial Quart':
                return value * 0.026021056;
            case 'Imperial Pint':
                return value * 0.052042112;
            case 'Imperial Fluid Ounce':
                return value * 1.0408422403;
            case 'Imperial Table Spoon':
                return value * 1.6653475844;
            case 'Imperial Tea Spoon':
                return value * 4.9960427532;
            case 'Cubic Mile':
                return value * 7.095066821E-15;
            case 'Cubic Yard':
                return value * 0.0000386807;
            case 'Cubic Foot':
                return value * 0.0010443788;
            case 'Cubic Inch':
                return value * 1.8046866495;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsTableSpoon = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0000147868;
            case 'Cubic Kilometer':
                return value * 1.478675781E-16;
            case 'Cubic Centimeter':
                return value * 14.786757812;
            case 'Cubic Millimeter':
                return value * 14786.757812;
            case 'Liter':
                return value * 0.0147867578;
            case 'Mililiter':
                return value * 14.786757812;
            case 'US Gallon':
                return value * 0.00390625;
            case 'US Quart':
                return value * 0.015625;
            case 'US Pint':
                return value * 0.03125;
            case 'US Cup':
                return value * 0.0625;
            case 'US Fluid Ounce':
                return value * 0.5;
            case 'US Table Spoon':
                return value;
            case 'US Tea Spoon':
                return value * 3;
            case 'Imperial Gallon':
                return value * 0.003252632;
            case 'Imperial Quart':
                return value * 0.013010528;
            case 'Imperial Pint':
                return value * 0.026021056;
            case 'Imperial Fluid Ounce':
                return value * 0.5204211201;
            case 'Imperial Table Spoon':
                return value * 0.8326737922;
            case 'Imperial Tea Spoon':
                return value * 2.4980213766;
            case 'Cubic Mile':
                return value * 3.54753341E-15;
            case 'Cubic Yard':
                return value * 0.0000193403;
            case 'Cubic Foot':
                return value * 0.0005221894;
            case 'Cubic Inch':
                return value * 0.9023433247;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromUsTeaSpoon = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0000049289;
            case 'Cubic Kilometer':
                return value * 4.92891927E-15;
            case 'Cubic Centimeter':
                return value * 4.9289192708;
            case 'Cubic Millimeter':
                return value * 4928.9192708;
            case 'Liter':
                return value * 0.0049289193;
            case 'Mililiter':
                return value * 4.9289192708;
            case 'US Gallon':
                return value * 0.0013020833;
            case 'US Quart':
                return value * 0.0052083333;
            case 'US Pint':
                return value * 0.0104166667;
            case 'US Cup':
                return value * 0.0208333333;
            case 'US Fluid Ounce':
                return value * 0.1666666667;
            case 'US Table Spoon':
                return value * 0.3333333333;
            case 'US Tea Spoon':
                return value;
            case 'Imperial Gallon':
                return value * 0.0010842107;
            case 'Imperial Quart':
                return value * 0.0043368427;
            case 'Imperial Pint':
                return value * 0.0086736853;
            case 'Imperial Fluid Ounce':
                return value * 0.1734737067;
            case 'Imperial Table Spoon':
                return value * 0.2775579307;
            case 'Imperial Tea Spoon':
                return value * 0.8326737922;
            case 'Cubic Mile':
                return value * 1.182511136E-15;
            case 'Cubic Yard':
                return value * 0.0000064468;
            case 'Cubic Foot':
                return value * 0.0001740631;
            case 'Cubic Inch':
                return value * 0.3007811082;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromImperialGallon = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.00454609;
            case 'Cubic Kilometer':
                return value * 4.54609E-12;
            case 'Cubic Centimeter':
                return value * 4546.09;
            case 'Cubic Millimeter':
                return value * 4546090;
            case 'Liter':
                return value * 4.54609;
            case 'Mililiter':
                return value * 4546.09;
            case 'US Gallon':
                return value * 1.2009504915;
            case 'US Quart':
                return value * 4.803801966;
            case 'US Pint':
                return value * 9.6076039319;
            case 'US Cup':
                return value * 19.215207864;
            case 'US Fluid Ounce':
                return value * 153.72166291;
            case 'US Table Spoon':
                return value * 307.44332582;
            case 'US Tea Spoon':
                return value * 922.32997747;
            case 'Imperial Gallon':
                return value;
            case 'Imperial Quart':
                return value * 4;
            case 'Imperial Pint':
                return value * 8;
            case 'Imperial Fluid Ounce':
                return value * 160;
            case 'Imperial Table Spoon':
                return value * 256;
            case 'Imperial Tea Spoon':
                return value * 768;
            case 'Cubic Mile':
                return value * 1.09066547E-12;
            case 'Cubic Yard':
                return value * 0.0059460612;
            case 'Cubic Foot':
                return value * 0.1605436532;
            case 'Cubic Inch':
                return value * 277.41943279;
            default:
                return 'Invalid unit';
        }
    };
    const convertFromImperialQuart = (value, toUnit) => {
        switch (toUnit) {
            case 'Cubic Meter':
                return value * 0.0011365225;
            case 'Cubic Kilometer':
                return value * 1.1365225E-12;
            case 'Cubic Centimeter':
                return value * 1136.5225;
            case 'Cubic Millimeter':
                return value * 1136522.5;
            case 'Liter':
                return value * 1.1365225;
            case 'Mililiter':
                return value * 1136.5225;
            case 'US Gallon':
                return value * 0.3002376229;
            case 'US Quart':
                return value * 1.2009504915;
            case 'US Pint':
                return value * 2.401900983;
            case 'US Cup':
                return value * 4.803801966;
            case 'US Fluid Ounce':
                return value * 38.430415728;
            case 'US Table Spoon':
                return value * 76.860831456;
            case 'US Tea Spoon':
                return value * 230.58249437;
            case 'Imperial Gallon':
                return value * 0.25;
            case 'Imperial Quart':
                return value;
            case 'Imperial Pint':
                return value * 2;
            case 'Imperial Fluid Ounce':
                return value * 40;
            case 'Imperial Table Spoon':
                return value * 64;
            case 'Imperial Tea Spoon':
                return value * 192;
            case 'Cubic Mile':
                return value * 2.726663675E-13;
            case 'Cubic Yard':
                return value * 0.0014865153;
            case 'Cubic Foot':
                return value * 0.0401359133;
            case 'Cubic Inch':
                return value * 69.354858198;
            default:
                return 'Invalid unit';
        }
    };
    const convertToImperialPint = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0005682613;
            case 'Cubic Kilometer':
                return value * 5.6826125E-13;
            case 'Cubic Centimeter':
                return value * 568.26125;
            case 'Cubic Millimeter':
                return value * 568261.25;
            case 'Liter':
                return value * 0.56826125;
            case 'Mililiter':
                return value * 568.26125;
            case 'US Gallon':
                return value * 0.1501188114;
            case 'US Quart':
                return value * 0.6004752457;
            case 'US Pint':
                return value * 1.2009504915;
            case 'US Cup':
                return value * 2.401900983;
            case 'US Fluid Ounce':
                return value * 19.215207864;
            case 'US Table Spoon':
                return value * 38.430415728;
            case 'US Tea Spoon':
                return value * 115.29124718;
            case 'Imperial Gallon':
                return value * 0.125;
            case 'Imperial Quart':
                return value * 0.5;
            case 'Imperial Pint':
                return value;
            case 'Imperial Fluid Ounce':
                return value * 20;
            case 'Imperial Table Spoon':
                return value * 32;
            case 'Imperial Tea Spoon':
                return value * 96;
            case 'Cubic Mile':
                return value * 1.363331837E-13;
            case 'Cubic Yard':
                return value * 0.0007432577;
            case 'Cubic Foot':
                return value * 0.0200679567;
            case 'Cubic Inch':
                return value * 34.677429099;
            default:
                return 'Invalid unit';
        }
    };
    const convertToImperialFluidOunce = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0000284131;
            case 'Cubic Kilometer':
                return value * 2.84130625E-14;
            case 'Cubic Centimeter':
                return value * 28.4130625;
            case 'Cubic Millimeter':
                return value * 28413.0625;
            case 'Liter':
                return value * 0.0284130625;
            case 'Mililiter':
                return value * 28.4130625;
            case 'US Gallon':
                return value * 0.0075059406;
            case 'US Quart':
                return value * 0.0300237623;
            case 'US Pint':
                return value * 0.0600475246;
            case 'US Cup':
                return value * 0.1200950491;
            case 'US Fluid Ounce':
                return value * 0.9607603932;
            case 'US Table Spoon':
                return value * 1.9215207864;
            case 'US Tea Spoon':
                return value * 5.7645623592;
            case 'Imperial Gallon':
                return value * 0.00625;
            case 'Imperial Quart':
                return value * 0.025;
            case 'Imperial Pint':
                return value * 0.05;
            case 'Imperial Fluid Ounce':
                return value;
            case 'Imperial Table Spoon':
                return value * 1.6;
            case 'Imperial Tea Spoon':
                return value * 4.8;
            case 'Cubic Mile':
                return value * 6.816659189E-15;
            case 'Cubic Yard':
                return value * 0.0000371629;
            case 'Cubic Foot':
                return value * 0.0010033978;
            case 'Cubic Inch':
                return value * 1.7338714549;
            default:
                return 'Invalid unit';
        }
    };
    const convertToImperialTableSpoon = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0000177582;
            case 'Cubic Kilometer':
                return value * 1.775816406E-14;
            case 'Cubic Centimeter':
                return value * 17.758164063;
            case 'Cubic Millimeter':
                return value * 17758.164063;
            case 'Liter':
                return value * 0.0177581641;
            case 'Mililiter':
                return value * 17.758164063;
            case 'US Gallon':
                return value * 0.0046912129;
            case 'US Quart':
                return value * 0.0187648514;
            case 'US Pint':
                return value * 0.0375297029;
            case 'US Cup':
                return value * 0.0750594057;
            case 'US Fluid Ounce':
                return value * 0.6004752457;
            case 'US Table Spoon':
                return value * 1.2009504915;
            case 'US Tea Spoon':
                return value * 3.6028514745;
            case 'Imperial Gallon':
                return value * 0.00390625;
            case 'Imperial Quart':
                return value * 0.015625;
            case 'Imperial Pint':
                return value * 0.03125;
            case 'Imperial Fluid Ounce':
                return value * 0.625;
            case 'Imperial Table Spoon':
                return value;
            case 'Imperial Tea Spoon':
                return value * 3;
            case 'Cubic Mile':
                return value * 4.260411993E-15;
            case 'Cubic Yard':
                return value * 0.0000232268;
            case 'Cubic Foot':
                return value * 0.0006271236;
            case 'Cubic Inch':
                return value * 1.0836696593;
            default:
                return 'Invalid unit';
        }
    };
    const convertToImperialTeaSpoon = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0000059194;
            case 'Cubic Kilometer':
                return value * 5.91938802E-15;
            case 'Cubic Centimeter':
                return value * 5.9193880208;
            case 'Cubic Millimeter':
                return value * 5919.3880208;
            case 'Liter':
                return value * 0.005919388;
            case 'Mililiter':
                return value * 5.9193880208;
            case 'US Gallon':
                return value * 0.0015637376;
            case 'US Quart':
                return value * 0.0062549505;
            case 'US Pint':
                return value * 0.012509901;
            case 'US Cup':
                return value * 0.0250198019;
            case 'US Fluid Ounce':
                return value * 0.2001584152;
            case 'US Table Spoon':
                return value * 0.4003168305;
            case 'US Tea Spoon':
                return value * 1.2009504915;
            case 'Imperial Gallon':
                return value * 0.0013020833;
            case 'Imperial Quart':
                return value * 0.0052083333;
            case 'Imperial Pint':
                return value * 0.0104166667;
            case 'Imperial Fluid Ounce':
                return value * 0.2083333333;
            case 'Imperial Table Spoon':
                return value * 0.3333333333;
            case 'Imperial Tea Spoon':
                return value;
            case 'Cubic Mile':
                return value * 1.420137331E-15;
            case 'Cubic Yard':
                return value * 0.0000077423;
            case 'Cubic Foot':
                return value * 0.0002090412;
            case 'Cubic Inch':
                return value * 0.3612232198;
            default:
                return 'Invalid unit';
        }
    };
    const convertToCubicMile = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 4168180000;
            case 'Cubic Kilometer':
                return value * 4.16818;
            case 'Cubic Centimeter':
                return value * 4168180000000000;
            case 'Cubic Millimeter':
                return value * 4168179999999999500;
            case 'Liter':
                return value * 4168180000000;
            case 'Mililiter':
                return value * 4168180000000000;
            case 'US Gallon':
                return value * 1101117184136;
            case 'US Quart':
                return value * 4404468736544;
            case 'US Pint':
                return value * 8808937473087;
            case 'US Cup':
                return value * 17617874946175;
            case 'US Fluid Ounce':
                return value * 140942999569399;
            case 'US Table Spoon':
                return value * 281885999138799;
            case 'US Tea Spoon':
                return value * 845657997416396;
            case 'Imperial Gallon':
                return value * 916871421375;
            case 'Imperial Quart':
                return value * 3667485685501;
            case 'Imperial Pint':
                return value * 7334971371002;
            case 'Imperial Fluid Ounce':
                return value * 146699427420047;
            case 'Imperial Table Spoon':
                return value * 234719083872075;
            case 'Imperial Tea Spoon':
                return value * 704157251616224;
            case 'Cubic Mile':
                return value;
            case 'Cubic Yard':
                return value * 5451773612.4;
            case 'Cubic Foot':
                return value * 147197887535;
            case 'Cubic Inch':
                return value * 254357949660781;
            default:
                return 'Invalid unit';
        }
    };
    const convertToCubicYard = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.764554858;
            case 'Cubic Kilometer':
                return value * 7.645548579E-10;
            case 'Cubic Centimeter':
                return value * 764554.85798;
            case 'Cubic Millimeter':
                return value * 764554857.98;
            case 'Liter':
                return value * 764.55485798;
            case 'Mililiter':
                return value * 764554.85798;
            case 'US Gallon':
                return value * 201.97412116;
            case 'US Quart':
                return value * 807.89648464;
            case 'US Pint':
                return value * 1615.7929693;
            case 'US Cup':
                return value * 3231.5859386;
            case 'US Fluid Ounce':
                return value * 25852.687509;
            case 'US Table Spoon':
                return value * 51705.375017;
            case 'US Tea Spoon':
                return value * 155116.12505;
            case 'Imperial Gallon':
                return value * 168.17855739;
            case 'Imperial Quart':
                return value * 672.71422958;
            case 'Imperial Pint':
                return value * 1345.4284592;
            case 'Imperial Fluid Ounce':
                return value * 26908.569183;
            case 'Imperial Table Spoon':
                return value * 43053.710693;
            case 'Imperial Tea Spoon':
                return value * 129161.13208;
            case 'Cubic Mile':
                return value * 1.834265453E-10;
            case 'Cubic Yard':
                return value;
            case 'Cubic Foot':
                return value * 27;
            case 'Cubic Inch':
                return value * 46656;
            default:
                return 'Invalid unit';
        }
    };
    const convertToCubicFoot = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0283168466;
            case 'Cubic Kilometer':
                return value * 2.831684659E-11;
            case 'Cubic Centimeter':
                return value * 28316.846592;
            case 'Cubic Millimeter':
                return value * 28316846.592;
            case 'Liter':
                return value * 28.316846592;
            case 'Mililiter':
                return value * 28316.846592;
            case 'US Gallon':
                return value * 7.480523006;
            case 'US Quart':
                return value * 29.922092024;
            case 'US Pint':
                return value * 59.844184048;
            case 'US Cup':
                return value * 119.6883681;
            case 'US Fluid Ounce':
                return value * 957.50694476;
            case 'US Table Spoon':
                return value * 1915.0138895;
            case 'US Tea Spoon':
                return value * 5745.0416686;
            case 'Imperial Gallon':
                return value * 6.228835459;
            case 'Imperial Quart':
                return value * 24.915341836;
            case 'Imperial Pint':
                return value * 49.830683672;
            case 'Imperial Fluid Ounce':
                return value * 996.61367345;
            case 'Imperial Table Spoon':
                return value * 1594.5818775;
            case 'Imperial Tea Spoon':
                return value * 4783.7456325;
            case 'Cubic Mile':
                return value * 6.793575755E-12;
            case 'Cubic Yard':
                return value * 0.037037037;
            case 'Cubic Foot':
                return value;
            case 'Cubic Inch':
                return value * 1728;
            default:
                return 'Invalid unit';
        }
    };
    const convertToCubicInch = (value, fromUnit) => {
        switch (fromUnit) {
            case 'Cubic Meter':
                return value * 0.0000163871;
            case 'Cubic Kilometer':
                return value * 1.6387064E-14;
            case 'Cubic Centimeter':
                return value * 16.387064;
            case 'Cubic Millimeter':
                return value * 16387.064;
            case 'Liter':
                return value * 0.016387064;
            case 'Mililiter':
                return value * 16.387064;
            case 'US Gallon':
                return value * 0.0043290064;
            case 'US Quart':
                return value * 0.0173160255;
            case 'US Pint':
                return value * 0.034632051;
            case 'US Cup':
                return value * 0.0692641019;
            case 'US Fluid Ounce':
                return value * 0.5541128153;
            case 'US Table Spoon':
                return value * 1.1082256305;
            case 'US Tea Spoon':
                return value * 3.3246768915;
            case 'Imperial Gallon':
                return value * 0.0036046501;
            case 'Imperial Quart':
                return value * 0.0144186006;
            case 'Imperial Pint':
                return value * 0.0288372012;
            case 'Imperial Fluid Ounce':
                return value * 0.576744024;
            case 'Imperial Table Spoon':
                return value * 0.9227904384;
            case 'Imperial Tea Spoon':
                return value * 2.7683713151;
            case 'Cubic Mile':
                return value * 3.931467451E-15;
            case 'Cubic Yard':
                return value * 0.0000214335;
            case 'Cubic Foot':
                return value * 0.0005787037;
            case 'Cubic Inch':
                return value;
            default:
                return 'Invalid unit';
        }
    };
    const convertUnits = () => {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            setOutputValue('');
            return;
        }

        let result;

        switch (fromUnit) {
            case 'Cubic Meter':
                result = convertFromCubicMeter(value, toUnit);
                break;
            case 'Cubic Kilometer':
                result = convertFromCubicKilometer(value, toUnit);
                break;
            case 'Cubic Centimeter':
                result = convertFromCubicCentimeter(value, toUnit);
                break;
            case 'Cubic Millimeter':
                result = convertFromCubicMillimeter(value, toUnit);
                break;
            case 'Liter':
                result = convertFromLiter(value, toUnit);
                break;
            case 'Mililiter':
                result = convertFromMililiter(value, toUnit);
                break;
            case 'US Gallon':
                result = convertFromUsGallon(value, toUnit);
                break;
            case 'US Quart':
                result = convertFromUsQuart(value, toUnit);
                break;
            case 'US Pint':
                result = convertFromUsPint(value, toUnit);
                break;
            case 'US Cup':
                result = convertFromUsCup(value, toUnit);
                break;
            case 'US Fluid Ounce':
                result = convertFromUsFluidOunce(value, toUnit);
                break;
            case 'US Table Spoon':
                result = convertFromUsTableSpoon(value, toUnit);
                break;
            case 'US Tea Spoon':
                result = convertFromUsTeaSpoon(value, toUnit);
                break;
            case 'Imperial Gallon':
                result = convertFromImperialGallon(value, toUnit);
                break;
            case 'Imperial Quart':
                result = convertFromImperialQuart(value, toUnit);
                break;
            case 'Imperial Pint':
                result = convertToImperialPint(value, toUnit);
                break;
            case 'Imperial Fluid Ounce':
                result = convertToImperialFluidOunce(value, toUnit);
                break;
            case 'Imperial Table Spoon':
                result = convertToImperialTableSpoon(value, toUnit);
                break;
            case 'Imperial Tea Spoon':
                result = convertToImperialTeaSpoon(value, toUnit);
                break;
            case 'Cubic Mile':
                result = convertToCubicMile(value, toUnit);
                break;
            case 'Cubic Yard':
                result = convertToCubicYard(value, toUnit);
                break;
            case 'Cubic Foot':
                result = convertToCubicFoot(value, toUnit);
                break;
            case 'Cubic Inch':
                result = convertToCubicInch(value, toUnit);
                break;
            // Add cases for other units as needed
            default:
                setOutputValue('Invalid unit selected');
                // alert('Invalid unit selected.');
                return;
        }

        setOutputValue(result.toString());
        setResultValue(`${value} ${fromUnit} = ${result.toString()} ${toUnit}`);
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFromUnitChange = (e) => {
        setFromUnit(e.target.value);
    };

    const handleToUnitChange = (e) => {
        setToUnit(e.target.value);
    };

    useEffect(() => {
        convertUnits();
    }, [inputValue, fromUnit, toUnit]);

    return (
        <Box className='box-wrapper my-4'>
            <Box className='percentage-field'>
                <Box className='unit-converter'>
                    <LabelStyled>{t('From')}</LabelStyled>
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                    <select value={fromUnit} onChange={handleFromUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-outline-secondary mt-4' >
                        =
                    </button>
                </Box>
                <Box className='unit-converter'>
                    <LabelStyled>{t('To')}</LabelStyled>
                    <input type="text" value={outputValue} readOnly />
                    <select value={toUnit} onChange={handleToUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
            </Box>
            {
                resultValue !== null && (
                    <Box className='mt-4 d-flex align-items-center justify-content-center'>
                        <SpanStyled className='btn btn-danger mx-2'>{t('Result')}: </SpanStyled>
                        <SpanStyled className='btn btn-outline-success mx-2'>{resultValue}</SpanStyled>
                    </Box>
                )
            }


        </Box>

    )
}

export default FindValue